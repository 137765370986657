<template>
  <div class="slider">
    <ul v-bind:style="{transform: 'translateX(' + currentSlide * -100 + '%)'}">
      <li v-for="(slide, index) in slides">
          <div class="testimonial-slide">
            <div class="testimonial">
                <div class="quote-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 100" width="50" height="50">
                        <path fill="#ace3ef" d="M23.105,55.627c-0.005,0.138-0.021,0.273-0.021,0.413c0,0.047,0.006,0.092,0.007,0.138    c-0.001,0.092-0.007,0.183-0.007,0.275l0.02-0.014c0.212,6.166,5.265,11.103,11.482,11.103c6.352,0,11.502-5.149,11.502-11.502    c0-5.796-4.292-10.577-9.869-11.372c1.386-4.595,5.645-7.938,10.695-7.938v-4.106C34.021,32.625,23.541,42.841,23.105,55.627z"/>
                        <path fill="#ace3ef" d="M52.941,55.627c-0.005,0.138-0.021,0.273-0.021,0.413c0,0.047,0.007,0.092,0.007,0.138    c-0.001,0.092-0.007,0.183-0.007,0.275l0.021-0.014c0.212,6.166,5.265,11.103,11.481,11.103c6.353,0,11.502-5.149,11.502-11.502    c0-5.796-4.292-10.577-9.869-11.372c1.386-4.595,5.645-7.938,10.694-7.938v-4.106C63.856,32.625,53.378,42.841,52.941,55.627z"/>
                    </svg>
                </div>
                {{slide.testimonial}}
            </div>
            <div class="attribution">&mdash; {{slide.attribution}}</div>
          </div>

        </a>
      </li>
    </ul>

    <nav class="slider-nav">
      <div v-on:click="slidePrevious" class="left"></div>
      <div v-on:click="slideNext" class="right"></div>
    </nav>
  </div>
</template>

<script>
  export default {
    props: {
      slides: Object
    },
    data() {
      return {
        currentSlide: 0,
        interval: null
      };
    },
    computed: {
      lastIndex: function() {
        return this.lastSlideIndex();
      }
    },
    methods: {
      slideNext: function () {
        if (this.currentSlide >= this.lastIndex) {
          this.currentSlide = 0;
        } else {
          this.currentSlide += 1;
        }
      },

      slidePrevious: function () {
        if (this.currentSlide <= 0) {
          this.currentSlide = this.lastIndex;
        } else {
          this.currentSlide -= 1;
        }
      },

      lastSlideIndex: function () {
        return Object.keys(this.slides).length - 1;
      }
    },
    created: function() {
      // this.interval = setInterval(() => {
      //   if (this.currentSlide >= this.lastSlideIndex()) {
      //     this.currentSlide = 0;
      //   } else {
      //     this.currentSlide += 1;
      //   }
      // }, 5000);
    },
    beforeDestroy: function() {
      clearInterval(this.interval);
    }
  }
</script>
